<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
            Buttons
        </h5>
        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="stepInText">Next Button Text</label>
                <input
                    id="stepInText"
                    v-model="nextButton.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="stepInLink">Link</label>
                <input
                    id="stepInLink"
                    v-model="nextButton.link"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-12">
                <b-form-checkbox v-model="nextButton.hidden" switch>
                    Hide Next Button
                </b-form-checkbox>
            </div>
            <div class="form-group col-12 col-md-6 mt-2">
                <label for="stepInText">Skip Button Text</label>
                <input
                    id="stepInText"
                    v-model="skipButton.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6 mt-2">
                <label for="stepInLink">Link</label>
                <input
                    id="stepInLink"
                    v-model="skipButton.link"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-12">
                <b-form-checkbox v-model="skipButton.hidden" switch>
                    Hide Skip Button
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        infoNextButton: {
            type: Object,
            required: false,
            default: null
        },
        infoSkipButton: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            nextButton: {
                hidden: false,
                text: '',
                link: '',
            },
            skipButton: {
                hidden: false,
                text: '',
                link: '',
            }
        }
    },

    watch: {
        nextButton: {
            handler() {
                this.$emit('update:infoNextButton', this.nextButton)
            },
            deep: true
        },
        skipButton: {
            handler() {
                this.$emit('update:infoSkipButton', this.skipButton)
            },
            deep: true
        }
    },

    mounted() {
        if (this.infoNextButton) {
            this.nextButton = {
                hidden: this.infoNextButton.hidden,
                text: this.infoNextButton.text,
                link: this.infoNextButton.link
            };
        }

        if (this.infoSkipButton) {
            this.skipButton = {
                hidden: this.infoSkipButton.hidden,
                text: this.infoSkipButton.text,
                link: this.infoSkipButton.link
            };
        }
    }
}
</script>
