function Single({
    content,
    showTitle,
    icon,
    redirectQuestionId,
    relatedProductSlug,
    productImages,
    identifier,
    activeByDefault,
    assignSku,
    sku,
    assignPostPurchaseQuestionnaire,
    relatedPostPurchaseQuestionnaireId
}) {
    this.content = content;
    this.showTitle = showTitle;
    this.icon = icon;
    this.redirectQuestionId = redirectQuestionId;
    this.relatedProductSlug = relatedProductSlug;
    this.productImages = productImages;
    this.identifier = identifier;
    this.activeByDefault = !!activeByDefault;
    this.assignSku = assignSku;
    this.sku = sku;
    this.assignPostPurchaseQuestionnaire = assignPostPurchaseQuestionnaire;
    this.relatedPostPurchaseQuestionnaireId = relatedPostPurchaseQuestionnaireId;
}

function Multiple({
    content,
    activeSubanswer,
    activeByDefault,
    icon,
    inactiveSubanswer,
    identifier,
    assignPostPurchaseQuestionnaire,
    relatedPostPurchaseQuestionnaireId
}) {
    this.content = content;
    this.activeSubanswer = !!activeSubanswer;
    this.activeByDefault = !!activeByDefault;
    this.icon = icon;
    this.inactiveSubanswer = !!inactiveSubanswer;
    this.identifier = identifier;
    this.assignPostPurchaseQuestionnaire = assignPostPurchaseQuestionnaire;
    this.relatedPostPurchaseQuestionnaireId = relatedPostPurchaseQuestionnaireId;
}

function Loading({ title, subtitle, order, image }) {
    this.title = title;
    this.subtitle = subtitle;
    this.order = order;
    this.image = image;
}

export { Single, Multiple, Loading };
