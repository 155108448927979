<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
            Button
        </h5>
        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="stepInText">Button text</label>
                <input
                    id="stepInText"
                    v-model="button.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="stepInLink">Button link</label>
                <input
                    id="stepInLink"
                    v-model="button.link"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        buttonObject: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            button: {
                text: '',
                link: '',
            }
        }
    },

    watch: {
        button: {
            handler: function() {
                this.$emit('update:buttonObject', this.button)
            },
            deep: true
        }
    },

    mounted() {
        if (this.buttonObject) {
            this.button = {
                text: this.buttonObject.text,
                link: this.buttonObject.link
            }
        }
    }
}
</script>
