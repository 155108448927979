<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light px-2 mt-0 mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <span>Messages</span>
                <div>
                    <button
                        class="btn btn-warning m-1"
                        type="button"
                        @click="onNewMessageButtonClick"
                    >
                        <span>{{ newMessageButtonText }}</span>
                    </button>
                </div>
            </div>
        </h5>
        <b-collapse id="add-message-section" :visible="isFormVisible">
            <div class="row">
                <div class="col-12">
                    <form
                        ref="addNewMessageForm"
                        @submit.prevent="onSubmitNewMessage"
                    >
                        <div class="row">
                            <media-chooser
                                v-model="newMessage.image"
                                label="Icon"
                                class="col-12"
                                style="margin-top: 0 !important;"
                            />
                            <div class="col-6 form-group">
                                <label for="title">Title</label>
                                <textarea
                                    id="title"
                                    v-model="newMessage.title"
                                    :class="{
                                        'is-invalid': $v.newMessage.title.$error
                                    }"
                                    class="form-control"
                                    rows="3"
                                    :error-messages="titleErrors"
                                    @input="$v.newMessage.title.$touch"
                                ></textarea>
                                <div
                                    v-if="titleErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ titleErrors[0] }}
                                </div>
                            </div>
                            <div class="col-6 form-group">
                                <label for="subtitle">Subtitle</label>
                                <textarea
                                    id="subtitle"
                                    v-model="newMessage.subtitle"
                                    :class="{
                                        'is-invalid':
                                            $v.newMessage.subtitle.$error
                                    }"
                                    class="form-control"
                                    rows="3"
                                    :error-messages="titleErrors"
                                    @input="$v.newMessage.subtitle.$touch"
                                />
                                <div
                                    v-if="titleErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ titleErrors[0] }}
                                </div>
                            </div>
                            <div class="col-12">
                                <button
                                    :disabled="$v.$invalid"
                                    class="btn btn-success"
                                    type="submit"
                                >
                                    {{ submitMessageButtonText }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
        </b-collapse>
        <table
            v-if="list.length"
            id="orders-datatable"
            class="table table-centered table-striped text-center my-1"
        >
            <thead class="d-flex col-12">
                <tr class="d-flex col-12">
                    <span class="col-5">Title</span>
                    <span class="col-5">Subtitle</span>
                    <span class="text-center col-2">Actions</span>
                </tr>
            </thead>
            <tbody class="d-flex">
                <draggable
                    v-bind="dragOptions"
                    :list="list"
                    class="col-12"
                    ghost-class="ghost"
                    @end="onElementMoved"
                    @start="drag = true"
                >
                    <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                        class="col-12 d-flex flex-wrap"
                        style="display:block"
                    >
                        <tr
                            v-for="(listElement, index) in list"
                            :key="index + 0"
                            class="col-12 d-flex"
                            style="cursor: grab; padding: 0"
                        >
                            <td class="col-5">
                                {{ listElement.title }}
                            </td>
                            <td class="col-5">
                                {{ listElement.subtitle }}
                            </td>
                            <td class="col-2 text-center">
                                <a
                                    style="cursor:pointer"
                                    @click="
                                        onEditMessageButtonClick(listElement)
                                    "
                                >
                                    <i class="fas fa-pen mr-1" />
                                </a>
                                <a
                                    style="cursor:pointer"
                                    @click="onDeleteMessageButtonClick(index)"
                                >
                                    <i class="far fa-trash-alt" />
                                </a>
                            </td>
                        </tr>
                    </transition-group>
                </draggable>
            </tbody>
        </table>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import MediaChooser from '@components/media/MediaChooser';
import QuestionLoadingValidation from './question-loading-validation.mixin';

export default {
    components: {
        Draggable,
        MediaChooser
    },

    mixins: [QuestionLoadingValidation],

    props: {
        initialValues: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            list: [],
            newMessage: {
                title: '',
                subtitle: '',
                image: {}
            },
            drag: false,
            updating: false,
            isFormVisible: false
        };
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            };
        },

        submitMessageButtonText() {
            return this.updating ? 'Update Message' : 'Add New Message';
        },

        newMessageButtonText() {
            return this.isFormVisible
                ? 'Hide New Message Form'
                : 'Show New Message Form';
        }
    },

    watch: {
        list: function(newList) {
            const listWithOrder = newList.map((item, index) => ({
                order: index + 1,
                ...item
            }));
            this.$emit('input', listWithOrder);
        }
    },

    mounted() {
        if (this.initialValues && this.initialValues.options) {
            this.list = [...this.initialValues.options];
        }
    },

    methods: {
        resetNewMessageInputs() {
            this.$v.newMessage.$reset();
            this.newMessage = {
                title: '',
                subtitle: '',
                image: {}
            };
        },

        onSubmitNewMessage() {
            if (this.updating) {
                this.list = [...this.list];
            } else {
                this.list = [...this.list, this.newMessage];
            }

            this.updating = false;
            this.resetNewMessageInputs();
        },

        onDeleteMessageButtonClick(indexToRemove) {
            this.list = this.list.filter(
                (item, index) => index !== indexToRemove
            );
        },

        onEditMessageButtonClick(element) {
            this.isFormVisible = true;
            this.updating = true;
            this.newMessage = element;
        },

        onNewMessageButtonClick() {
            this.isFormVisible = !this.isFormVisible;
            this.updating = false;
            this.resetNewMessageInputs();
        },

        onElementMoved() {
            this.drag = false;
        }
    }
};
</script>
