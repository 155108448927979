<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
            Options
        </h5>
        <div class="row">
            <div class="form-group col-12 col-md-6 mt-2">
                <label for="stepInText">Skip Button Text</label>
                <input
                    id="stepInText"
                    v-model="skipButton.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6 mt-2">
                <label for="stepInLink">Link</label>
                <input
                    id="stepInLink"
                    v-model="skipButton.link"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-12">
                <b-form-checkbox v-model="skipButton.hidden" switch>
                    Hide Skip Button
                </b-form-checkbox>
            </div>
        </div>

        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
            Quantity Selection
        </h5>
        <div class="form-group col-12">
            <b-form-checkbox v-model="discountValue" switch>
                Show discount value
            </b-form-checkbox>
        </div>
        <div class="form-group col-12">
            <wysiwyg-editor v-model="promotionText" label="Promotion text" />
        </div>
        <div class="form-group col-12">
            <b-form-checkbox v-model="defaultQuantity" switch>
                Mark default quantity from product page
            </b-form-checkbox>
        </div>
        <div
            v-for="(quantity, index) in quantitySelection"
            :key="index"
        >
            <div class="row col-12">
                <div class="col-3 form-group mb-2">
                    <label :for="`quantity_value_${index}`">
                        Quantity
                    </label>
                    <input
                        :id="`quantity_value_${index}`"
                        v-model.number="quantity.value"
                        type="number"
                        min="1"
                        class="form-control"
                        placeholder="Enter number"
                    />
                </div>
                <div class="col-4 form-group mb-2">
                    <label :for="`quantity_qtyText_${index}`">
                        Qty text
                    </label>
                    <input
                        :id="`quantity_qtyText_${index}`"
                        v-model="quantity.qtyText"
                        class="form-control"
                        type="text"
                        placeholder="Enter quantity text"
                    />
                </div>
                <div class="col-4 form-group mb-2">
                    <label :for="`quantity_priceText_${index}`">
                        Price text
                    </label>
                    <input
                        :id="`quantity_priceText_${index}`"
                        v-model="quantity.priceText"
                        class="form-control"
                        type="text"
                        placeholder="Enter price text"
                    />
                </div>
                <div class="col-1 d-flex align-items-center">
                    <button
                        class="btn btn-danger waves-effect waves-light"
                        type="button"
                        @click="deleteQuantitySelection(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </div>
            </div>

            <div v-if="!quantity.secondaryProduct" :key="key" class="row col-12">
                <div class="col-4 form-group d-flex align-items-end mb-3 mt-2 pl-4">
                    <button
                        type="button"
                        class="btn btn-success waves-effect waves-light add-button"
                        @click="addSecondaryProduct(index)"
                    >
                        <i class="mdi mdi-plus" />
                        Add Product
                    </button>
                </div>
            </div>

            <div v-else :key="key" class="row col-12">
                <div class="form-group col-4 mb-3 pl-4">
                    <label>
                        Other Product
                    </label>
                    <b-form-select
                        v-model="quantity.secondaryProduct.slug"
                        :options="secondaryProductOptions"
                        :class="{
                            'is-invalid':
                                validations.question.quantity_selection.$each[
                                    index
                                ].secondaryProduct.slug.$error
                        }"
                    />
                </div>
                <div class="form-group col-3">
                    <label for="secondaryProductPrice">
                        Price
                    </label>
                    <currency-input-new
                        id="product_price"
                        v-model.number="quantity.secondaryProduct.price"
                        type="text"
                        class="form-control"
                        placeholder="Price"
                    />
                </div>
                <div class="col-4 form-group d-flex align-items-end mb-3">
                    <button
                        class="btn btn-danger waves-effect waves-light"
                        type="button"
                        @click="removeSecondaryProduct(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </div>
            </div>
        </div>
        <button
            class="btn btn-info waves-effect waves-light mb-2 ml-2"
            type="button"
            @click="addQuantitySelection"
        >
            <i class="fas fa-plus" />
        </button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import CurrencyInputNew from '@components/elements/CurrencyInputNew';

export default {
    components: {
        WysiwygEditor,
        CurrencyInputNew
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({})
        },
        quantitySkipButton: {
            type: Object,
            required: false,
            default: null
        },
        validations: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            discountValue: false,
            defaultQuantity: false,
            quantitySelection: [],
            promotionText: '',
            question: {},
            skipButton: {
                text: '',
                link: '',
                hidden: false
            },
            secondaryProducts: [],
            selectedSecondaryProducts: [],
            key: 0
        };
    },

    computed: {
        secondaryProductOptions() {
            return this.secondaryProducts.map(product => {
                return {
                    text: product.name,
                    value: product.slug
                }
            })
        }
    },

    watch: {
        discountValue: {
            handler() {
                this.question.discount_value = this.discountValue;

                this.$emit('input', this.question);
            }
        },

        defaultQuantity: {
            handler() {
                this.question.default_quantity = this.defaultQuantity;

                this.$emit('input', this.question);
            }
        },

        promotionText: {
            handler() {
                this.question.promotion_text = this.promotionText;

                this.$emit('input', this.question);
            }
        },

        quantitySelection: {
            handler() {
                this.question.quantity_selection = this.quantitySelection;

                this.$emit('input', this.question);
            }
        },

        skipButton: {
            handler() {
                this.$emit('update:quantitySkipButton', this.skipButton);
            },

            deep: true
        }
    },

    async mounted() {
        this.question = { ...this.value };

        const {
            quantity_selection,
            promotion_text,
            discount_value,
            default_quantity
        } = this.question;

        this.discountValue = !!discount_value;

        this.defaultQuantity = !!default_quantity;

        if (promotion_text) {
            this.promotionText = promotion_text;
        }

        if (quantity_selection && quantity_selection.length) {
            this.quantitySelection = [...quantity_selection];
        }

        if (this.quantitySkipButton) {
            this.skipButton = {
                text: this.quantitySkipButton.text,
                link: this.quantitySkipButton.link,
                hidden: this.quantitySkipButton.hidden
            };
        }

        await this.fetchSecondaryProducts();
    },

    methods: {
        ...mapActions({
            getSecondaryProducts: 'secondaryProducts/index'
        }),

        async fetchSecondaryProducts() {
            try {
                const { rows } = await this.getSecondaryProducts();

                this.secondaryProducts = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        addQuantitySelection() {
            let value = 1;

            if (this.quantitySelection.length) {
                const { value: currentValue } = this.quantitySelection[
                    this.quantitySelection.length - 1
                ];

                value = currentValue ? currentValue + 1 : value;
            }

            this.quantitySelection.push({
                value,
                qtyText: '',
                priceText: ''
            });
        },

        deleteQuantitySelection(index) {
            this.quantitySelection.splice(index, 1);
        },

        addSecondaryProduct(index) {
            this.quantitySelection[index].secondaryProduct = {
                slug: '',
                price: 0
            };

            this.key ++;
        },

        removeSecondaryProduct(index) {
            this.quantitySelection[index].secondaryProduct = null;

            this.key ++;
        }
    }
};
</script>
