import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import appConfig from '@src/app.config';
import QuestionBubbleTalk from '@components/questions/QuestionBubbleTalk';
import QuestionQuantity from '@components/questions/QuestionQuantity';
import QuestionVideo from '@components/questions/QuestionVideo';
import QuestionInfoButtons from '@components/questions/QuestionInfoButtons';
import QuestionAnswersOptionsTable from '@components/questions/QuestionAnswersOptionsTable';
import QuestionLoadingButton from '@components/questions/question-loading/QuestionLoadingButton';
import QuestionLoadingMessagesTable from '@components/questions/question-loading/QuestionLoadingMessagesTable';

export default {
    page: {
        title: 'Questionnaires',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    validations: {
        question: {
            title: { required },
            summary_text: {
                requiredIf: (v, obj) => {
                    if (!obj.hide_in_summary && obj.type !== 'size') {
                        return !!v;
                    }

                    return true;
                }
            },
            quantity_selection: {
                $each: {
                    secondaryProduct: {
                        slug: {
                            requiredIfProductExists: (value, vm) => {
                                if (vm) {
                                    return required(value)
                                }
    
                                return true;
                            }
                        },
                        price: {
                            requiredIfProductExists: (value, vm) => {
                                if (vm) {
                                    return required(value)
                                }
    
                                return true;
                            }
                        }
                    }
                }
            }
        }
    },

    components: {
        QuestionQuantity,
        QuestionVideo,
        QuestionBubbleTalk,
        QuestionInfoButtons,
        QuestionLoadingButton,
        QuestionAnswersOptionsTable,
        QuestionLoadingMessagesTable
    },

    data() {
        return {
            title: 'Questionnaires',
            types: [
                {
                    text: 'Open Question',
                    value: 'open'
                },
                {
                    text: 'Short Answer',
                    value: 'short'
                },
                {
                    text: 'Radio Picker/Single Answer',
                    value: 'single'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Size',
                    value: 'size'
                },
                {
                    text: 'Multi-Select',
                    value: 'multiple'
                },
                {
                    text: 'Information',
                    value: 'info'
                },
                {
                    text: 'Loading Page',
                    value: 'loading'
                },
                {
                    text: 'Quantity',
                    value: 'quantity'
                },
                {
                    text: 'Video',
                    value: 'video'
                }
            ],
            dataLayerTagOptions: [
                'Activity Type',
                'Sub-activity Type',
                'Health Condition',
                'Men-Women',
                'Shoe type',
                'Removal inserts',
                'Pain Type',
                'Comment',
                'Everyday-Sports',
                'Color',
                'Changing question'
            ],
            answerOptions: [],
            questionNextButton: null,
            questionSkipButton: null,
            initValueStringify: '',
            initOptionsStringify: '',
            checkoutFields: [
                {
                    text: '',
                    value: ''
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Full Name',
                    value: 'fullName'
                },
                {
                    text: 'Address',
                    value: 'address'
                },
                {
                    text: 'Apt, Suite, Etc',
                    value: 'apartment'
                },
                {
                    text: 'Company Name',
                    value: 'company'
                },
                {
                    text: 'Town/City',
                    value: 'city'
                },
                {
                    text: 'Country',
                    value: 'country'
                },
                {
                    text: 'State',
                    value: 'state'
                },
                {
                    text: 'Zip',
                    value: 'zip'
                },
                {
                    text: 'Phone Number',
                    value: 'phone'
                }
            ],
            products: [],
            questionnaireId: null,
            isBubbleTalk: false,
            bubbleTalk: {},
            questions: [],
            subquestions: []
        };
    },

    computed: {
        redirectLink() {
            const { productId, bundleId } = this.$route.query;

            let link = '/questionnaires';

            if (productId) {
                link = `/products/${productId}/edit`;
            } else if (bundleId) {
                link = `/bundles/${bundleId}/edit`;
            }

            if (this.questionnaireId) {
                link += `?questionnaire=${this.questionnaireId}`;
            }

            return link;
        },

        titleErrors() {
            const errors = [];

            if (!this.$v.question.title.$dirty) return errors;

            !this.$v.question.title.required &&
                errors.push('Title is required.');
            !this.$v.question.title.maxLength &&
                errors.push('Title can not contain more than 500 chars.');

            return errors;
        },

        summaryTextErrors() {
            const errors = [];

            if (!this.$v.question.summary_text.$dirty) return errors;

            !this.$v.question.summary_text.requiredIf &&
                errors.push('Summary text is required');

            return errors;
        },

        getQuestionOptions() {
            return this.question &&
                this.question.value &&
                this.question.value.options
                ? this.question.value.options
                : [];
        }
    },

    async created() {
        await this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            getQuestions: 'questionnaires/getQuestions',
        }),

        async fetchQuestions() {
            try {
                this.questions = await this.getQuestions(this.questionnaireId);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchProducts() {
            try {
                const { rows } = await this.getProducts();

                this.products = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchSubquestions() {
            const questionId = this.$route.params.id;

            try {
                this.subquestions = await this.getSubquestions({
                    questionnaireId: this.questionnaireId,
                    questionId
                });
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        onClearSingleOption(index) {
            this.answerOptions = [
                ...this.answerOptions.slice(0, index),
                ...this.answerOptions.slice(index + 1)
            ];
            this.question.value.options = [...this.answerOptions];
        },

        onClearAllOption() {
            this.answerOptions = [];
            this.question.value.options = [];
        },

        onAddBubbleTalk(bubbleTalk) {
            this.bubbleTalk = bubbleTalk;
        },

        onQuestionQuantityInput(questionQuantity) {
            this.question = {
                ...this.question,
                ...questionQuantity
            };
        }
    }
};
