<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light px-2 mt-0 mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <span>Videos</span>
                <div>
                    <button
                        class="btn btn-warning m-1"
                        type="button"
                        @click="addItem"
                    >
                        <i class="fas fa-plus-square mr-1" />
                        New Video
                    </button>
                    <button
                        v-if="videoOptions.length"
                        class="btn btn-danger m-1"
                        type="button"
                        @click="clearItems"
                    >
                        <i class="fe-trash-2 mr-1" />
                        Clear Videos
                    </button>
                </div>
            </div>
        </h5>

        <div class="row">
            <div :key="videoOptions.length" class="col-12 p-2">
                <b-card
                    v-for="(videoOption, index) in videoOptions"
                    :key="index"
                    class="card-custom-border-color"
                >
                    <b-card-title
                        class="d-flex flex-row justify-content-between"
                    >
                        <h4>
                            {{ index + 1 }}
                        </h4>
                        <div class="col-2 d-flex justify-content-end align-items-start">
                            <button
                                v-if="index"
                                v-b-tooltip.hover.top
                                title="Increase Priority"
                                class="btn btn-danger waves-effect waves-light mr-1"
                                type="button"
                                @click="moveItem(index, index - 1)"
                            >
                                <i class="fas fa-arrow-up" />
                            </button>
                            <button
                                v-if="index !== videoOptions.length - 1"
                                v-b-tooltip.hover.top
                                title="Reduce Priority"
                                class="btn btn-danger waves-effect waves-light mr-1"
                                type="button"
                                @click="moveItem(index, index + 1)"
                            >
                                <i class="fas fa-arrow-down" />
                            </button>
                            <button
                                class="btn btn-outline-danger btn-small mx-2"
                                type="button"
                                @click="deleteItem(index)"
                            >
                                <i class="fe-trash" />
                            </button>
                        </div>
                    </b-card-title>

                    <video-content
                        v-model="videoOption.video"
                        class="row"
                    />

                    <div class="row">
                        <div class="form-group col-12">
                            <label :for="`video_description_${index}`">
                                Description
                            </label>
                            <textarea
                                :id="`video_description_${index}`"
                                v-model="videoOption.description"
                                class="form-control"
                                rows="2"
                            />
                        </div>

                        <b-form-group
                            label="Related Question"
                            class="col-6"
                        >
                            <b-form-select
                                v-model="
                                    videoOption.relatedQuestionId
                                "
                                :options="relatedQuestions"
                                text-field="title"
                                value-field="id"
                            />
                        </b-form-group>

                        <b-form-group
                            label="Related Answer"
                            class="col-6"
                        >
                            <b-form-select
                                v-model="
                                    videoOption.relatedAnswerIdentifier
                                "
                                :options="getRelatedAnswers(videoOption.relatedQuestionId)"
                                text-field="content"
                                value-field="identifier"
                            />
                        </b-form-group>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import VideoContent from '@components/elements/contents-builder-elements/Video';

export default {
    components: {
        VideoContent
    },

    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        questions: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            videoOptions: []
        };
    },

    computed: {
        relatedQuestions() {
            const possibleTypes = ['single', 'multiple'];

            return this.questions
                .filter(question => possibleTypes.includes(question.type))
                .map(question => ({
                    ...question,
                    value: JSON.parse(question.value)
                }));
        }
    },

    watch: {
        videoOptions: {
            handler() {
                this.$emit('input', this.videoOptions);
            },
            deep: true
        }
    },

    created() {
        this.videoOptions = [...this.value];
    },

    methods: {
        addItem() {
            this.videoOptions = [
                ...this.videoOptions,
                {
                    video: {},
                    description: ''
                }
            ];
        },

        moveItem(from, to) {
            this.videoOptions.splice(
                to,
                0,
                this.videoOptions.splice(from, 1)[0]
            );
        },

        deleteItem(index) {
            this.videoOptions.splice(index, 1);
        },

        clearItems() {
            this.videoOptions = [];
        },

        getRelatedAnswers(questionId) {
            if (!questionId) {
                return [];
            }

            const question = this.relatedQuestions.find(
                question => question.id === questionId
            );

            if (!question) {
                return [];
            }

            return question.value.options;
        }
    }
};
</script>
