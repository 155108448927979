import { required, maxLength } from 'vuelidate/lib/validators';

export default {
    validations: {
        newMessage: {
            title: { required, maxLength: maxLength(500) },
            subtitle: { maxLength: maxLength(1000) },
        },
    },

    computed: {
        titleErrors() {
            const errors = [];

            if (!this.$v.newMessage.title.$dirty) return errors;

            !this.$v.newMessage.title.required &&
            errors.push('Title is required.');
            !this.$v.newMessage.title.maxLength &&
            errors.push('Title can not contain more than 500 chars.');

            return errors;
        },

        subtitleErrors() {
            const errors = [];

            if (!this.$v.newMessage.subtitle.$dirty) return errors;

            !this.$v.newMessage.subtitle.maxLength &&
            errors.push('Subtitle can not contain more than 1000 chars.');

            return errors;
        }
    }
}
